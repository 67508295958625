import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import "./styles.css";

export default function Form({ payload }: { payload: any }) {
  return (
    <Grid2 xs={12}>
      <Grid2 container columns={12} spacing={3}>
        <Grid2 xs={12} sx={{ textAlign: "center" }}>
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <Typography variant="h3" sx={{ my: 2 }}>
            提交成功，謝謝！
            <br />
            <span style={{ fontSize: "60%" }}>
              Submitted successfully. Thanks!
            </span>
          </Typography>
          <Typography variant="h5" sx={{ my: 2 }}>
            {payload?.name}
            <br />
            {payload?.phoneNumber}
          </Typography>
          <Typography variant="h4" sx={{ my: 1 }}>
            {payload?.submitTime}
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
