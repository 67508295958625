import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import "dayjs/locale/zh-hk";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import apiCaller from "../utils/apiCaller";

export default function Form({
  onSuccess,
}: {
  onSuccess: (value: any) => void;
}) {
  // shouldValidateForm - should I show the error messages?
  const [shouldValidateForm, setShouldValidateForm] = useState(false);

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birthMonth, setBirthMonth] = useState(""); // [1, 12]
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  const formRef = useRef<HTMLFormElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const otpRef = useRef<HTMLInputElement>(null);

  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [otpCanResendCountdown, setOtpCanResendCountdown] = useState<
    number | null
  >(null);
  const otpCanResendCountdownTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const otpAbortController = useRef<AbortController | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendOtp = useCallback(async () => {
    if (phoneNumber.length !== 8) {
      toast.error(
        "請輸入8位數字的電話號碼 Please enter an 8-digit phone number"
      );

      phoneNumberRef.current?.focus();

      return;
    }

    setIsSendingOtp(true);

    otpCanResendCountdownTimeoutId.current &&
      clearInterval(otpCanResendCountdownTimeoutId.current);

    try {
      await apiCaller.post("sendOtp", {
        phoneNumber,
      });
      otpRef.current?.focus();

      setOtpCanResendCountdown(30);
      otpCanResendCountdownTimeoutId.current = setInterval(() => {
        setOtpCanResendCountdown((prev) => {
          if (prev === null) {
            return null;
          }
          if (prev <= 0) {
            otpCanResendCountdownTimeoutId.current &&
              clearInterval(otpCanResendCountdownTimeoutId.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.error?.message) {
        toast.error(error.response.data.error.message);
        return;
      }
      toast.error(
        "目前未能發送一次性密碼，請重試。Unable to send one-time password at the moment, please try again."
      );
    } finally {
      setIsSendingOtp(false);

      // https://developer.chrome.com/docs/identity/web-apis/web-otp#use_the_webotp_api
      if ("OTPCredential" in window) {
        if (otpAbortController.current) {
          otpAbortController.current.abort();
          otpAbortController.current = null;
        }

        const ac = new AbortController();

        navigator.credentials
          .get({
            //@ts-expect-error
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            // console.log(otp);
            //@ts-expect-error
            setOtp(otp.code);
            // if (form) form.submit();
          })
          .catch((err) => {
            // There may be DOMException: OTP retrieval timed out.
            // DOMException: signal is aborted without reason
            console.error("WebOTP API error ↓");
            console.error(err);
          })
          .finally(() => {
            otpAbortController.current = null;
          });

        otpAbortController.current = ac;
      }
    }
  }, [phoneNumber]);

  const submit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const w = await apiCaller.post("submit", {
        name,
        gender,
        birthMonth,
        phoneNumber,
        otp,
      });
      onSuccess(w.data);
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.error?.message) {
        toast.error(error.response.data.error.message);
        return;
      }
      toast.error(
        "目前未能提交，請重試。Unable to submit at the moment, please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [name, gender, birthMonth, phoneNumber, otp, onSuccess]);

  useEffect(() => {
    return () => {
      otpCanResendCountdownTimeoutId.current &&
        clearInterval(otpCanResendCountdownTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    return () => {
      otpAbortController.current && otpAbortController.current.abort();
    };
  }, []);

  return (
    <Grid2 xs={12}>
      <form
        ref={formRef}
        // put noValidate as validation occurs before the 'submit' event
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          setShouldValidateForm(true);
          formRef.current?.checkValidity();
          if (formRef.current?.reportValidity()) {
            submit();
          }
        }}
      >
        <Grid2 container columns={12} spacing={3}>
          <Grid2 xs={12}>
            <TextField
              type="text"
              name="name"
              id="name"
              label="姓名 Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              fullWidth
              autoComplete="name"
              required
              InputProps={{ readOnly: isSubmitting }}
            />
          </Grid2>

          <Grid2 xs={12}>
            <FormControl fullWidth>
              <InputLabel id="sex-label">性別 Gender</InputLabel>
              <Select
                name="sex"
                id="sex"
                labelId="sex-label"
                label="性別 Gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                fullWidth
                autoComplete="sex"
                required
                readOnly={isSubmitting}
              >
                <MenuItem value="M">男 Male</MenuItem>
                <MenuItem value="F">女 Female</MenuItem>
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 xs={12}>
            <FormControl fullWidth>
              <InputLabel id="bday-month-label">
                出生月份 Birth Month
              </InputLabel>
              <Select
                name="bday-month"
                id="bday-month"
                labelId="bday-month-label"
                label="出生月份 Birth Month"
                value={birthMonth}
                onChange={(event) => setBirthMonth(event.target.value)}
                fullWidth
                autoComplete="bday-month"
                required
                readOnly={isSubmitting}
              >
                {[...Array(12)].map((month, i) => (
                  <MenuItem key={i + 1} value={i + 1}>
                    {dayjs().locale("zh-HK").set("month", i).format("MMMM")}{" "}
                    {dayjs().set("month", i).format("MMMM")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>

          <Grid2 xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                inputRef={phoneNumberRef}
                type="tel"
                name="tel"
                id="tel"
                label="電話號碼 Phone Number"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                variant="outlined"
                fullWidth
                autoComplete="tel"
                required
                inputProps={{ minLength: 8, maxLength: 8 }}
                InputProps={{ readOnly: isSubmitting }}
              />
              {otpCanResendCountdown !== null ? (
                <LoadingButton
                  loading={isSendingOtp}
                  disabled={otpCanResendCountdown > 0 || isSubmitting}
                  onClick={sendOtp}
                >
                  重寄一次性密碼 Re-send One-time Password
                  {otpCanResendCountdown > 0
                    ? ` (${otpCanResendCountdown}秒 sec)`
                    : ``}
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isSendingOtp}
                  disabled={isSubmitting}
                  onClick={sendOtp}
                >
                  取得一次性密碼 Get One-time Password
                </LoadingButton>
              )}
            </Box>
          </Grid2>

          <Grid2 xs={12}>
            <TextField
              inputRef={otpRef}
              type="text"
              name="one-time-code"
              id="one-time-code"
              label="一次性密碼 One-time Password"
              value={otp}
              onChange={(event) => setOtp(event.target.value || "")}
              variant="outlined"
              fullWidth
              inputProps={{
                inputMode: "numeric",
                minLength: 6,
                maxLength: 6,
                pattern: "\\d{6}",
              }}
              autoComplete="one-time-code"
              required
              InputProps={{ readOnly: isSubmitting }}
            />
          </Grid2>

          <Grid2
            xs={12}
            sx={{
              "& .error-msg-pics": { display: "none" },
              ...(shouldValidateForm
                ? {
                    ":has(input:invalid) .error-msg-pics": { display: "block" },
                  }
                : {}),
            }}
          >
            <p
              className="error-msg-pics"
              style={{ marginTop: 4, marginBottom: 4, color: "red" }}
            >
              Required 必須選擇
            </p>
            <FormControlLabel
              required
              control={<Checkbox sx={{ paddingTop: 0 }} />}
              label={
                <div style={{ fontSize: "80%" }}>
                  <Typography component="span" color="error" fontSize="inherit">
                    *
                  </Typography>{" "}
                  本人已明白及同意
                  <Link
                    href="https://drive.google.com/file/d/1r411f2gilZbGo_PnaqH1qRp1RAUzQ9Rj/view?usp=sharing"
                    target="_blank"
                  >
                    《收集個人資料聲明》
                  </Link>
                  和
                  <Link
                    href="https://drive.google.com/file/d/13OarmIzmxwAF-QcQbDpCz4AosnkkaFJE/view?usp=sharing"
                    target="_blank"
                  >
                    《免責聲明》
                  </Link>
                  。 I have understood and agreed to the{" "}
                  <Link
                    href="https://drive.google.com/file/d/1r411f2gilZbGo_PnaqH1qRp1RAUzQ9Rj/view?usp=sharing"
                    target="_blank"
                  >
                    "Personal Information Collection Statement"
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://drive.google.com/file/d/13OarmIzmxwAF-QcQbDpCz4AosnkkaFJE/view?usp=sharing"
                    target="_blank"
                  >
                    "Disclaimer"
                  </Link>
                  .
                </div>
              }
              sx={{
                alignItems: "flex-start",
                ...(shouldValidateForm
                  ? { ":has(input:invalid)": { color: "red" } }
                  : {}),
              }}
            />
          </Grid2>

          <Grid2 xs={12}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                py: 1.5,
                ":not(.Mui-disabled):not(.MuiLoadingButton-loading)": {
                  background:
                    "linear-gradient(90deg, rgba(86, 5, 20, 1) 0%, rgba(134, 2, 39, 1) 60%, rgba(155, 2, 49, 1) 100%)",
                },
              }}
              disabled={otpCanResendCountdown === null}
              loading={isSubmitting}
            >
              提交 Submit
            </LoadingButton>
          </Grid2>
        </Grid2>
      </form>
    </Grid2>
  );
}
