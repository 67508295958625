import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import logo from "./assets/logo_500w.png";
import Form from "./pages/Form";
import Success from "./pages/Success";

const theme = responsiveFontSizes(
  createTheme({
    palette: { primary: { main: "#751F24" } },
    components: {
      MuiTextField: {
        defaultProps: {
          // Disable the "*" display for required field
          InputLabelProps: { required: false },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          // Disable the "*" display for required field
          asterisk: { display: "none" },
        },
      },
    },
  })
);

function App() {
  const [successPayload, setSuccessPayload] =
    //
    useState(undefined);
  // useState({
  //   submitTime: "2021-10-10 10:10:10",
  //   name: "陳大文",
  //   phoneNumber: "69119572",
  // });

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ my: 3 }}>
        <CssBaseline />
        <ToastContainer />
        <Grid2 container spacing={3}>
          <Grid2 xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <img
                src={logo}
                style={{ maxWidth: "100%", width: 250 }}
                alt="卡士蘭 Nu Pharm"
              />
            </Box>
          </Grid2>

          {successPayload ? (
            <Success payload={successPayload} />
          ) : (
            <Form onSuccess={setSuccessPayload} />
          )}
          {/* <Success payload={successPayload} /> */}
        </Grid2>
      </Container>
    </ThemeProvider>
  );
}

export default App;
