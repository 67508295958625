import axios from "axios";

const apiCaller = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://${window.location.hostname}:5001/socif-nu-pharm-form/asia-east2/`
      : "https://asia-east2-socif-nu-pharm-form.cloudfunctions.net/",
  // "https://asia-east2-socif-nu-pharm-form.cloudfunctions.net/",
});

// Wrapper for onCall protocol
// https://firebase.google.com/docs/functions/callable-reference
apiCaller.interceptors.request.use((config) => {
  config.data ??= {};
  const data = config.data;
  config.data = {};
  config.data.data = data;
  return config;
});

// Wrapper for onCall protocol
// https://firebase.google.com/docs/functions/callable-reference
apiCaller.interceptors.response.use((response) => response.data.result);

export default apiCaller;
